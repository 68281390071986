import {AfterViewInit, Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PersonalData} from '../../../models/personalData';
import * as searchAction from '../../../components/search/search.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.reducers';
import {Router} from '@angular/router';
import {Helper} from '../../../services/helper';
import {TagManagerService} from '../../../services/tag-manager.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.sass']
})
export class PersonalDataComponent implements OnInit, OnDestroy, AfterViewInit {
  html: string;
  countries: any;
  isPortugal: boolean;
  isFrance: boolean;
  isAndorra: boolean;
  isSpain = true;
  isSpainBill = true;
  isPortugalBill: boolean;
  isFranceBill: boolean;
  isAndorraBill: boolean;
  lan: string;
  interval;
  observe: any;
  @ViewChild('nifSpain') nifSpain: ElementRef;
  @ViewChild('nifPortugal') nifPortugal: ElementRef;
  @ViewChild('nifFrance') nifFrance: ElementRef;
  @ViewChild('nifAndorra') nifAndorra: ElementRef;
  @ViewChild('personalForm') myForm: NgForm;
  selectedCountryCode = 'es';
  selectedCountryCodeBill = 'es';
  countryCodes = ['es', 'pt', 'fr', 'ad'];
  equal: boolean;
  errors: string;
  txtEmailConfirm: string;
  notifications = false;
  data: string;
  public billShow = false;
  txtNif: string;
  txtName: string;
  isDni = true;
  isDniBill = true;
  personalData: PersonalData = new PersonalData(
    '',
    '',
    '',
    '',
    this.lan,
    'ES',
    '',
    '',
    this.lan,
    '',
    '',
    this.notifications,
      {idioma: this.lan,
        codigoPais: 'ES'},
    '',
    ''
  );
  dataTagManager = false;

  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>,
    private router: Router,
    private helper: Helper,
    private tag: TagManagerService
  ) {
  }

  changeSelectedCountryCode(value: string, type = null): void {
    if (type == null) {
      this.isDni = false;
      this.isAndorra = false;
      this.isSpain = false;
      this.isPortugal = false;
      this.isFrance = false;
      if (value === 'es') {
        this.isSpain = true;
      } else if (value === 'pt') {
        this.isPortugal = true;
      } else if (value === 'fr') {
        this.isFrance = true;
      } else if (value === 'ad') {
        this.isAndorra = true;
      }
    } else {
      this.isDniBill = false;
      this.isAndorraBill = false;
      this.isSpainBill = false;
      this.isPortugalBill = false;
      this.isFranceBill = false;
      if (value === 'es') {
        this.isSpainBill = true;
      } else if (value === 'pt') {
        this.isPortugalBill = true;
      } else if (value === 'fr') {
        this.isFranceBill = true;
      } else if (value === 'ad') {
        this.isAndorraBill = true;
      }
    }
    if (type == null) {
      this.personalData.dni = null;
      this.selectedCountryCode = value;
    } else {
      this.selectedCountryCodeBill = value;

      this.personalData.invoiceData.dni = null;
    }
  }

  ngAfterViewInit(): void {
    this.helper.getScreenSize('');
  }

  ngOnInit() {
    localStorage.removeItem('back');
    this.observe = this.store.subscribe((state) => {
      if (state.address.countries) {
        this.countries = state.address.countries;
        this.lan = state.address.lan;
      }
    });
    // Analytics
    this.tag.sendDynamicDataLayer({
      processCategory: '',
      processName: 'Datos personales',
      processStep: '1',
      processStatus: '3'
    });
  }

  ngOnDestroy(): void {
    this.observe.unsubscribe();
  }

  open(content, event: Event) {
    event.stopPropagation();
    this.modalService.open(content);
  }

  updateSelectedCountry(event: Event, countryCode: string) {
    event.preventDefault();
  }

  goPayment() {
    if (this.personalData.invoiceData == undefined) {
      delete this.personalData.invoiceData;
    } else {
      if (this.personalData.invoiceData.dni == undefined) {
        delete this.personalData.invoiceData;

      } else {
        this.personalData.invoiceData.idioma = this.lan;
      }

    }
    this.personalData.idioma = this.lan;
    this.personalData.codigoIdioma = this.lan;
    this.personalData.codigoPais = this.lan;

    const accion = new searchAction.SavePersonalData(this.personalData);
    this.store.dispatch(accion);
    this.router.navigateByUrl('/payment-proccess/payment-data');

    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '9Fin form datos personales OK',
      eventLabel: '9Fin form datos personales OK'
    });
  }

  validateMail() {
    this.equal = this.personalData.email.toLowerCase() == this.txtEmailConfirm.toLowerCase();
  }

  onlyDni() {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = this.personalData.dni.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      this.isDni = false;
      return false;
    }
    const nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;
    this.isDni = validChars.charAt(charIndex) === letter;
  }

  validate(type = null) {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const cifRexp = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;
    let str;
    if (type != null) {
      str = this.personalData.invoiceData.dni.toString().toUpperCase();
    } else {
      str = this.personalData.dni.toString().toUpperCase();
    }

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      if (type != null) {
        this.isDniBill = false;
      } else {
        this.isDni = false;
      }
      if (cifRexp.test(str)) {
        if (type != null) {
          this.isDniBill = true;
        } else {
          this.isDni = true;
        }
        return true;
      } else {
        return false;
      }
    }
    const nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;
    if (validChars.charAt(charIndex) === letter) {
      if (type != null) {
        this.isDniBill = true;
      } else {
        this.isDni = true;
      }
    } else {
      if (type != null) {
        this.isDniBill = false;
      } else {
        this.isDni = false;
      }
    }
  }

  validateNIF(type = null) {
    let nif;
    if (type != null) {
      nif = this.personalData.invoiceData.dni.toString().toUpperCase();
    } else {
      nif = this.personalData.dni.toString().toUpperCase();
    }
    let control;
    if (nif.length == 9) {
      const added = nif[7] * 2 + nif[6] * 3 + nif[5] * 4 + nif[4] * 5 + nif[3] * 6 + nif[2] * 7 + nif[1] * 8 + nif[0] * 9;
      const mod = added % 11;
      if (mod == 0 || mod == 1) {
        control = 0;
      } else {
        control = 11 - mod;
      }

      if (nif[8] == control) {
        if (type != null) {
          this.isDniBill = true;
        } else {
          this.isDni = true;
        }
      } else {
        if (type != null) {
          this.isDniBill = false;
        } else {
          this.isDni = false;
        }
      }
    } else {
      if (type != null) {
        this.isDniBill = false;
      } else {
        this.isDni = false;
      }
    }

    // console.log('dni correcto ' + this.isDni);
    // console.log(!this.equal + ' ' + !this.isDni + ' ' + !this.isDniBill);

  }

  clickName(event){
    console.log(event.charCode);
    return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 32 || event.charCode == 241
  }

  validateAD(type = null) {
    if (type != null) {
      this.isDniBill = true;
    } else {
      this.isDni = true;
    }
  }

  validateFrance(evt, type = null) {
    if (type != null) {
      this.isDniBill = true;
    } else {
      this.isDni = true;
    }
  }

  showBill() {
    this.billShow = !this.billShow;
    if (this.billShow === false) {
      this.isDniBill = true;
    }
  }

  focusInputGaEvent() {
    // Analytics
    if (!this.dataTagManager) {
      this.tag.sendDynamicDataLayer({
        event: 'gaEvent',
        eventCategory: 'Proceso de Abonos',
        eventAction: '8Inicio form datos personales',
        eventLabel: '8Inicio form datos personales'
      });
      this.dataTagManager = true;
    }
    // Analytics
    if (!this.dataTagManager) {
      this.tag.sendDynamicDataLayer({
        event: 'gaEvent',
        eventCategory: 'Proceso de Abonos',
        eventAction: '8Inicio form datos personales',
        eventLabel: '8Inicio form datos personales'
      });
      this.dataTagManager = true;
    }
  }

  closePrivacyModalGaEvent() {
    this.modalService.dismissAll();
    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '8.1Cerrar terminos y condiciones',
      eventLabel: '8.1Cerrar terminos y condiciones'
    });
  }

  focusoutInputGaEvent(e) {
    const name = e.target.name;
    if (name === 'txtNifPortugal') {
      if (!this.equal || !this.isDni || !this.isDniBill) {
        // Analytics
        this.tag.sendDynamicDataLayer({
          event: 'gaEvent',
          eventCategory: 'Proceso de Abonos',
          eventAction: '9.1Fin form datos personales KO',
          eventLabel: '9.1Campos de error ' + name
        });
      }
    } else if (name === 'txtNif') {
      if (!this.isDni) {
        // Analytics
        this.tag.sendDynamicDataLayer({
          event: 'gaEvent',
          eventCategory: 'Proceso de Abonos',
          eventAction: '9.1Fin form datos personales KO',
          eventLabel: '9.1Campos de error ' + name
        });
      }
    } else if (name === 'txtCity') {
      this.tag.sendDynamicDataLayer({
        procesoLocalidad: e.target.value
      });
    } else {
      if (this.myForm.form.controls[name].errors) {
        console.log('9.1Campo de error ' + name);
        // Analytics
        this.tag.sendDynamicDataLayer({
          event: 'gaEvent',
          eventCategory: 'Proceso de Abonos',
          eventAction: '9.1Fin form datos personales KO',
          eventLabel: '9.1Campos de error ' + name
        });
      }
    }
  }
  onlyText(event) {

    let key = event.charCode;
    console.log(key);
    return ((key >= 65 && key <= 90) || key == 8);
  }
}
