import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ParkingServices {
    private url = environment.envName ;
    //private url = '/assets/mock/parking.json';

    constructor(private http: HttpClient){}

    search(coordinates) {
        return this.http.post(`${ this.url }r/aparcamiento`, coordinates).pipe(map( parkings => parkings ));
    }

    getParking(idParking, lan) {
        return this.http.get(`${ this.url }r/aparcamiento/${idParking}/${lan}`).pipe(map( parking => parking ));

    }
    getParkingEmpark(idParking, lan) {
        return this.http.get(`${ this.url }r/parkbyexternalid/${idParking}/${lan}`).pipe(map( parking => parking ));

    }
    createVoucher(voucher) {
        return this.http.post(`${ this.url }w/abono`, voucher).pipe(map( url => url ));

    }

    getTermsAndConditions(idParking, lan){
        return this.http.get(`${ this.url }r/conditions/${idParking}/${lan}`).pipe(map( parking => parking ));
    }

    getCountries(lan){
        return this.http.get(`${ this.url }r/countries/${lan}`).pipe(map( countries => countries ));
    }

    orderSummary(hash, lan){
        return this.http.get(`${ this.url }r/ordersummary/${hash}/${lan}`).pipe(map( countries => countries ));
    }

    getAmount(data){
        return this.http.post(`${ this.url }firstamountrate`, data).pipe(map( countries => countries ));
    }
}
