import {Fare} from './fare';
import {PersonalData} from './personalData';

export class Voucher {
    public multibanco: boolean;
    public personalData: PersonalData;
    public invoiceData: any;
    public tarifa: Fare;
    constructor( multibanco: boolean, personalData: any, tarifa: any ) {
        this.multibanco = multibanco;
        this.personalData = personalData;
        this.tarifa = tarifa;
    }
}
