import {Action} from '@ngrx/store';


export const SEARCH_ADDRESS = '[SEARCH] Buscar dirección y conseguir coordenadas';
export const SEARCH_PARKING = '[SEARCH] Buscar parking con respecto a dos puntos NE, SW';
export const SEARCH_PARKING_SUCCESS = '[SEARCH] Parking cargados correctamente';
export const SEARCH_PARKING_FAIL = '[SEARCH] Error al cargar los parking';

export const GET_PARKING = '[PARKING] Cargar datos de un parking';
export const GET_PARKING_SUCCESS = '[PARKING] Datos del parking cargatos correctamente';
export const GET_PARKING_FAIL = '[PARKING] Error al cargar los datos del parking';

export const GET_PARKING_EMPARK = '[PARKING] Cargar datos de un parking empark';
export const GET_PARKING_EMPARK_SUCCESS = '[PARKING] Datos del parking empark cargatos correctamente';
export const GET_PARKING_EMPARK_FAIL = '[PARKING] Error al cargar los datos del parking parking';

export const GET_AMOUNT = '[AMOUNT] Dame el precio';
export const GET_AMOUNT_SUCCESS = '[AMOUNT] precio cargado correctamente';
export const GET_AMOUNT_FAIL = '[AMOUNT] Error al cargar el precio';

export const SELECT_FARE = '[FARE] Seleccionar tarifa de un parking';
export const SAVE_LAN = '[LAN] Guardar idioma web';

export const CREATE_VOUCHER = '[BONO] Crear bono';
export const CREATE_VOUCHER_SUCCESS = '[BONO] Bono creado correctamente';
export const CREATE_VOUCHER_FAIL = '[BONO] Error al crear un bono';

export const GET_TERMS = '[POLITICA] Solicitar terminos y condiciones';
export const GET_TERMS_SUCCESS = '[POLITICA] Terminos y condiciones cargados correctamente';
export const GET_TERMS_FAIL = '[POLITICA] Error al obtener terminos y condiciones';

export const GET_COUNTRIES = '[COUNTRIES] Solicitar países';
export const GET_COUNTRIES_SUCCESS = '[COUNTRIES] países cargados correctamente';
export const GET_COUNTRIES_FAIL = '[COUNTRIES] Error al obtener países';

export const GET_SUMMARY = '[SUMMARY] Solicitar detalle del pago';
export const GET_SUMMARY_SUCCESS = '[SUMMARY] detalle del pago cargado correctamente';
export const GET_SUMMARY_FAIL = '[SUMMARY] Error al obtener el detalle del pago';

export const SAVE_PERSONAL_DATA = '[PERSONAL_DATA] Guardar datos personales';
export const CLEAR_STATE = '[CLEAR] Limpiar estado';

export class ClearStateAction implements Action {
  readonly type = CLEAR_STATE;

  constructor() {
  }
}

export class SaveLanAction implements Action {
  readonly type = SAVE_LAN;

  constructor(public lan: any) {
  }
}

export class GetSummaryAction implements Action {
  readonly type = GET_SUMMARY;

  constructor(public hash: any, public lan) {
  }
}

export class GetSummarySuccess implements Action {
  readonly type = GET_SUMMARY_SUCCESS;

  constructor(public summary: any) {
  }
}

export class GetSummaryFail implements Action {
  readonly type = GET_SUMMARY_FAIL;

  constructor(public payload: any) {
  }
}

export class GetAmount implements Action {
  readonly type = GET_AMOUNT;

  constructor(public data: any) {
  }
}

export class GetAmountSuccess implements Action {
  readonly type = GET_AMOUNT_SUCCESS;

  constructor(public amount: any) {
  }
}

export class GetAmountFail implements Action {
  readonly type = GET_AMOUNT_FAIL;

  constructor(public payload: any) {
  }
}

export class GetCountriesAction implements Action {
  readonly type = GET_COUNTRIES;

  constructor(public lan: any) {
  }
}

export class GetCountriesSuccess implements Action {
  readonly type = GET_COUNTRIES_SUCCESS;

  constructor(public countries: any) {
  }
}

export class GetCountriesFail implements Action {
  readonly type = GET_COUNTRIES_FAIL;

  constructor(public payload: any) {
  }
}

export class GetTermsAction implements Action {
  readonly type = GET_TERMS;

  constructor(public idParking: any, public lan) {
  }
}

export class GetTermsSuccess implements Action {
  readonly type = GET_TERMS_SUCCESS;

  constructor(public terms: any) {
  }
}

export class GetTermsFail implements Action {
  readonly type = GET_TERMS_FAIL;

  constructor(public payload: any) {
  }
}

export class SearchAddressAction implements Action {
  readonly type = SEARCH_ADDRESS;

  constructor(public address: any) {
  }
}

export class SearchParkingAction implements Action {
  readonly type = SEARCH_PARKING;

  constructor(public coordinates: any) {
  }
}

export class SearchParkingSuccess implements Action {
  readonly type = SEARCH_PARKING_SUCCESS;

  constructor(public parkings: any) {
  }
}

export class SearchParkingFail implements Action {
  readonly type = SEARCH_PARKING_FAIL;

  constructor(public payload: any) {
  }
}

export class GetParkingFail implements Action {
  readonly type = GET_PARKING_FAIL;

  constructor(public payload: any) {
  }
}

export class GetParkingSuccess implements Action {
  readonly type = GET_PARKING_SUCCESS;

  constructor(public parking: any) {
  }
}

export class GetParking implements Action {
  readonly type = GET_PARKING;

  constructor(public idParking: any, public lan) {
  }
}

export class GetParkingEmparkFail implements Action {
  readonly type = GET_PARKING_EMPARK_FAIL;

  constructor(public payload: any) {
  }
}

export class GetParkingEmparkSuccess implements Action {
  readonly type = GET_PARKING_EMPARK_SUCCESS;

  constructor(public parking: any) {
  }
}

export class GetParkingEmpark implements Action {
  readonly type = GET_PARKING_EMPARK;

  constructor(public idParking: any, public lan) {
  }
}

export class CreateVoucher implements Action {
  readonly type = CREATE_VOUCHER;

  constructor(public voucher: any) {
  }
}

export class SavePersonalData implements Action {
  readonly type = SAVE_PERSONAL_DATA;

  constructor(public personalData: any) {
  }
}

export class CreateVoucherSucces implements Action {
  readonly type = CREATE_VOUCHER_SUCCESS;

  constructor(public url: any) {
  }
}

export class CreateVoucherFail implements Action {
  readonly type = CREATE_VOUCHER_FAIL;

  constructor(public payload: any) {
  }
}

export class SelectFare implements Action {
  readonly type = SELECT_FARE;

  constructor(public fare: any) {
  }
}

export type Acciones = SearchAddressAction |
  SearchParkingAction |
  SearchParkingSuccess |
  SearchParkingFail |
  GetParkingFail |
  CreateVoucher |
  CreateVoucherSucces |
  CreateVoucherFail |
  GetParkingSuccess |
  SavePersonalData |
  SelectFare |
  GetSummaryAction |
  GetSummarySuccess |
  GetSummaryFail |
  SaveLanAction |
  GetCountriesAction |
  GetCountriesSuccess |
  GetCountriesFail |
  GetTermsAction |
  GetTermsSuccess |
  GetTermsFail |
  GetParkingEmpark |
  GetAmount |
  GetAmountSuccess |
  GetAmountFail |
  GetParkingEmparkFail |
  ClearStateAction |
  GetParkingEmparkSuccess |
  GetParking;
