import { AfterViewInit, Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';
import { Voucher } from '../../../models/voucher';
import * as searchAction from '../../../components/search/search.actions';
import { ActivatedRoute, CanDeactivate, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Helper } from '../../../services/helper';
import { TagManagerService } from '../../../services/tag-manager.service';

@Component({
  selector: 'app-payment-data',
  templateUrl: './payment-data.component.html',
  styleUrls: ['./payment-data.component.sass']
})
export class PaymentDataComponent implements OnInit, OnDestroy, AfterViewInit {
  public multibankShow = false;
  public cardShow = false;
  voucher: Voucher = new Voucher(false, '', '');
  txtPhone: string;
  finish = false;
  secondStep = false;
  url: string;
  isPortugal: boolean;
  hash: string;
  lan: string;
  observe: any;
  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private helper: Helper,
    private location: LocationStrategy,
    private tag: TagManagerService
  ) {}

  ngAfterViewInit(): void {
    this.helper.getScreenSize('');
  }

  ngOnInit() {
    this.observe = this.store.subscribe((state) => {
      this.voucher.personalData = state.address.personalData;
      this.txtPhone = state.address.personalData.telefono;
      this.voucher.tarifa = state.address.fare;
      if (state.address.url != undefined) {
        window.location.href = state.address.url.url;
      }
      if (state.address.parking) {
        state.address.parking.countryCode == 'ES' ? (this.isPortugal = false) : (this.isPortugal = true);
      }
      this.lan = state.address.lan;
    });
    this.route.queryParams.subscribe((params) => {
      if (params['url'] != undefined) {
        this.url = params['url'];
        this.secondStep = true;
      }
      if (params['hash'] != undefined) {
        if (this.isPortugal) {
          this.secondStep = false;
        }
        this.finish = true;
        this.hash = params['hash'];

        // Analytics
        this.tag.sendDynamicDataLayer({
          event: 'gaEvent',
          eventCategory: 'Proceso de Abonos',
          eventAction: '12Cuenta bancaria añadida',
          eventLabel: '12Cuenta bancaria añadida'
        });
      }
      if (params['hash'] == undefined && params['url'] == undefined) {
        if (localStorage.getItem('back') == 'true') {
          this.router.navigateByUrl('/');
        }
      }
    });
    // Analytics
    this.tag.sendDynamicDataLayer({
      processCategory: '',
      processName: 'Datos personales',
      processStep: '1',
      processStatus: '3'
    });
  }

  ngOnDestroy(): void {
    this.observe.unsubscribe();
  }

  open(content, event: Event) {
    event.stopPropagation();
    this.modalService.open(content);
  }
  goPayment() {
    localStorage.setItem('back', 'true');
    let date = new Date(this.voucher.tarifa.fechaInicio);
    this.voucher.tarifa.fechaInicio =
      date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    this.voucher.multibanco = this.multibankShow;
    if(this.voucher.personalData.invoiceData != undefined){
      this.voucher.invoiceData = this.voucher.personalData.invoiceData;
    }
    const accion = new searchAction.CreateVoucher(this.voucher);
    this.store.dispatch(accion);

    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '10Seleccion pago con tarjeta',
      eventLabel: '10Seleccion pago con tarjeta'
    });
  }

  goSummary() {
    const accion = new searchAction.GetSummaryAction(this.hash, this.lan);
    this.store.dispatch(accion);
    this.router.navigateByUrl('/payment-proccess/confirm-ok');

    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '13Confirmar pago',
      eventLabel: '13Confirmar pago'
    });
  }

  goSecondPayment() {
    window.location.href = this.url;

    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '11Añadir cuenta bancaria',
      eventLabel: '11Añadir cuenta bancaria'
    });
  }
}
