import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './subscriptions/home/home.component';
import { MapComponent } from './subscriptions/map/map.component';
import { PaymentProccessComponent } from './subscriptions/payment-proccess/payment-proccess.component';
import { PAYMENT_PROCCESS_ROUTES } from './subscriptions/payment-proccess/payment-proccess.routes';

const routes: Routes = [
  { path: 'home/:lan/:country', component: HomeComponent },
  { path: 'map', component: MapComponent },
  { path: 'map/:idAparcamiento/:lan', component: MapComponent },
  { path: 'payment-proccess', component: PaymentProccessComponent, children: PAYMENT_PROCCESS_ROUTES },
  { path: '', pathMatch: 'full', redirectTo: 'home/es/es' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
