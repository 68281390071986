import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './app.reducers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { TagManagerService } from './services/tag-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('error') error: ElementRef;
  title = 'empark-ost';
  show = false;
  textError: string;
  observe: any;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private translate: TranslateService,
    private router: Router,
    private tag: TagManagerService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
  }

  ngOnInit() {
    this.observe = this.store.subscribe((state) => {
      this.show = state.address.loading;
      if (state.address.error) {
        this.textError = state.address.error.message;
        this.modalService.dismissAll();
        this.modalService.open(this.error);
      }
      if (state.address.lan) {
        this.translate.use(state.address.lan.toLocaleLowerCase());
      }
    });
    this.sendAnalyticsOnRouting();
  }

  ngOnDestroy(): void {
    this.observe.unsubscribe();
  }

  sendAnalyticsOnRouting() {
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.tag.sendStaticDataLayer();
      }
    });
  }
}
