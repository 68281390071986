import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import * as searchAction from '../../components/search/search.actions';
import { Coordinates } from '../../models/coordinates';
import { Helper } from '../../services/helper';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { TagManagerService } from '../../services/tag-manager.service';
declare const google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.sass']
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {
  map: google.maps.Map;
  lat = 40.416775;
  lng = -3.70379;
  zoom = 10;
  parkingLength: number;
  parkings: any = [];

  observe: any;
  image: any;
  interval;
  lan: string;
  show = true;
  @ViewChild('mapContainer') gmap: ElementRef;
  marker: any;
  MERCATOR_RANGE = 256; // full Earth map is 256x256 image.
  pixelOrigin = new google.maps.Point(this.MERCATOR_RANGE / 2, this.MERCATOR_RANGE / 2);
  pixelsPerLonDegree = this.MERCATOR_RANGE / 360;
  pixelsPerLonRadian = this.MERCATOR_RANGE / (2 * Math.PI);
  address: string;
  idParking: number;
  private route$: Subscription;

  constructor(
    private store: Store<AppState>,
    private helper: Helper,
    private activeRoute: ActivatedRoute,
    private tag: TagManagerService
  ) {}

  ngAfterViewInit(): void {
    this.helper.getScreenSize('map');
    this.route$ = this.activeRoute.params.subscribe((params: Params) => {
      this.idParking = params['idAparcamiento'];
      if (this.idParking != undefined) {
        this.openView(false, this.idParking);
      }
    });
  }

  ngOnInit() {
    this.observe = this.store.subscribe((state) => {
      if (state.address.search) {
        this.closeDetail(null);
        this.lat = state.address.coordinates.lat;
        this.lng = state.address.coordinates.lng;
        this.mapInitializer();
        this.getParkings(this.lat, this.lng);
      }
      this.lan = state.address.lan;

      if (state.address.parkings != undefined) {
        this.address = state.address.coordinates.name;
        this.parkingLength = state.address.parkings.length;
        this.printMarkerInMap(state.address.parkings);
      }

      if (state.address.parking != undefined) {
        this.show = false;
      }

    });
  }

  ngOnDestroy(): void {
    this.observe.unsubscribe();
    this.route$.unsubscribe();
  }

  mapInitializer() {
    const coordinates = new google.maps.LatLng(this.lat, this.lng);
    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      zoom: this.zoom,
      styles: [
        {
          stylers: [
            {
              saturation: -100
            }
          ]
        }
      ]
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    this.pixelOrigin = new google.maps.Point(this.MERCATOR_RANGE / 2, this.MERCATOR_RANGE / 2);
    this.pixelsPerLonDegree = this.MERCATOR_RANGE / 360;
    this.pixelsPerLonRadian = this.MERCATOR_RANGE / (2 * Math.PI);

    this.map.addListener('dragend', () => {
      this.getParkings(this.map.getCenter().lat(), this.map.getCenter().lng());
    });
  }

  initMap() {}

  closeDetail($event) {
    this.show = true;
  }

  openView($event, id = undefined) {
    console.log($event);
    if (id != undefined) {
      this.show = false;
    } else if ($event == false && this.show == false) {
      this.show = true;
      this.interval = setInterval(() => {
        this.show = $event;
        clearInterval(this.interval);
      }, 100);
    } else {
      this.show = $event;
    }
  }

  // Get corner map

  bound(value, optMin, optMax) {
    if (optMin != null) value = Math.max(value, optMin);
    if (optMax != null) value = Math.min(value, optMax);
    return value;
  }

  degreesToRadians(deg) {
    return deg * (Math.PI / 180);
  }

  radiansToDegrees(rad) {
    return rad / (Math.PI / 180);
  }

  fromLatLngToPoint(latLng) {
    const point = new google.maps.Point(0, 0);
    const origin = this.pixelOrigin;
    point.x = origin.x + latLng.lng() * this.pixelsPerLonDegree;
    // NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
    // 89.189.  This is about a third of a tile past the edge of the world tile.
    const siny = this.bound(Math.sin(this.degreesToRadians(latLng.lat())), -0.9999, 0.9999);
    point.y = origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -this.pixelsPerLonRadian;
    return point;
  }

  fromPointToLatLng(point) {
    const origin = this.pixelOrigin;
    const lng = (point.x - origin.x) / this.pixelsPerLonDegree;
    const latRadians = (point.y - origin.y) / -this.pixelsPerLonRadian;
    const lat = this.radiansToDegrees(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);
    return new google.maps.LatLng(lat, lng);
  }

  getCorners(center, zoom, mapWidth, mapHeight) {
    const scale = Math.pow(2, this.map.getZoom());
    const centerPx = this.fromLatLngToPoint(this.map.getCenter());
    const SWPoint = { x: centerPx.x - mapWidth / 2 / scale, y: centerPx.y + mapHeight / 2 / scale };
    const SWLatLon = this.fromPointToLatLng(SWPoint);
    const NEPoint = { x: centerPx.x + mapWidth / 2 / scale, y: centerPx.y - mapHeight / 2 / scale };
    const NELatLon = this.fromPointToLatLng(NEPoint);

    for (var i = 0; i < this.parkings.length; i++) {
      this.parkings[i].setMap(null);
    }

    const coordinates = new Coordinates('es', NELatLon, SWLatLon);
    const accion = new searchAction.SearchParkingAction(coordinates);
    this.store.dispatch(accion);
  }
  getParkings(lat, lng) {
    const marker = new google.maps.LatLng(lat, lng);
    this.getCorners(
      marker,
      this.zoom,
      this.gmap.nativeElement.offsetWidth !== 0 ? this.gmap.nativeElement.offsetWidth : 1920,
      this.gmap.nativeElement.offsetHeight !== 0 ? this.gmap.nativeElement.offsetHeight : 1080
    );
  }

  printMarkerInMap(parkings) {
    if (parkings != undefined) {
      let i;
      for (i = 0; i < parkings.length; i++) {
        this.createMarker(this.cheapPrice(parkings[i].tarifasList), parkings[i], i);
      }
    }
  }
  createMarker(price, parking, i) {
    const imageObj = new Image();
    imageObj.src = '/assets/img/shape.png';
    imageObj.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 60;
      canvas.height = 39;
      const context = canvas.getContext('2d');
      context.fillStyle = '#faa636';
      context.font = '15px Arial';
      context.textAlign = 'center';
      context.drawImage(imageObj, 0, 0);
      if (price != 0) {
        context.fillText(price + ' €', 30, 20);
      } else {
        context.font = '8px Arial';

        context.fillText('Próximamente', 30, 20);
      }
      this.image = {
        url: canvas.toDataURL()
      };
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(parking.coordenadas.latitud, parking.coordenadas.longitud),
        map: this.map,
        icon: this.image
      });
      this.parkings.push(marker);
      if (price != 0) {
        google.maps.event.addListener(
          marker,
          'click',
          ((marker, i, map) => {
            return () => {
              // Analytics
              this.tag.sendDynamicDataLayer({
                event: 'gaEvent',
                eventCategory: 'Proceso de Abonos',
                eventAction: '3.2Seleccion parking mapa',
                eventLabel: `3.2${parking.name}_${parking.city}`
              });

              const accion = new searchAction.GetParking(parking.idAparcamiento, this.lan);
              this.store.dispatch(accion);
              this.openView(false);
            };
          })(marker, i, this.map)
        );
      }
    };
  }

  cheapPrice(fareList) {
    if (fareList.length === 0) {
      return 0;
    }
    const pricesDesc = [];
    fareList.forEach((fare) => {
      if (fare.precioConDescuento != null) {
        pricesDesc.push(fare.precioConDescuento);
      }else{
        pricesDesc.push(fare.precio);
      }
    });

    let temp = [];
    for (let i = 1; i < pricesDesc.length; i++) {
      for (let j = 0; j < pricesDesc.length - 1; j++) {
        if (pricesDesc[j + 1] < pricesDesc[j]) {
          temp = pricesDesc[j];
          pricesDesc[j] = pricesDesc[j + 1];
          pricesDesc[j + 1] = temp;
        }
      }
    }

    if (pricesDesc[0] != 0) {
      return pricesDesc[0].toString();
    } else {
      let result = null;
      result = pricesDesc[pricesDesc.length -1 ].toString();
      if ( result == null) {
        return '';
      } else {
        return result.toString();
      }
    }

  }
}
