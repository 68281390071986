import {ActionReducerMap} from '@ngrx/store';
import * as searchReducer from './components/search/search.reducer';


export interface AppState {
    address: any;
}
export const AppReducers: ActionReducerMap<AppState> = {
    address: searchReducer.searchReducer
};
