import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as searchAction from './search.actions';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {ParkingServices} from '../../services/parking.services';
import {GetAmount} from './search.actions';

@Injectable()
export class SearchEffects {
    constructor(private actions$: Actions, public parkingService: ParkingServices){}

    @Effect()
    searchParking$ = this.actions$.pipe(ofType(searchAction.SEARCH_PARKING))
        .pipe(
            switchMap( (action: searchAction.SearchParkingAction) => {
                return this.parkingService.search(action.coordinates)
                    .pipe(
                        map( parkings => new searchAction.SearchParkingSuccess(parkings) ),
                        catchError( error => of(new searchAction.SearchParkingFail(error)) )
                    );
            })
        );

    @Effect()
    parking$ = this.actions$.pipe(ofType(searchAction.GET_PARKING))
        .pipe(
            switchMap( (action: searchAction.GetParking) => {
                return this.parkingService.getParking(action.idParking, action.lan)
                    .pipe(
                        map( parking => new searchAction.GetParkingSuccess(parking) ),
                        catchError( error => of(new searchAction.GetParkingFail(error)) )
                    );
            })
        );

    @Effect()
    emparkParking$ = this.actions$.pipe(ofType(searchAction.GET_PARKING_EMPARK))
        .pipe(
            switchMap( (action: searchAction.GetParkingEmpark) => {
                return this.parkingService.getParkingEmpark(action.idParking, action.lan)
                    .pipe(
                        map( parking => new searchAction.GetParkingEmparkSuccess(parking) ),
                        catchError( error => of(new searchAction.GetParkingEmparkFail(error)) )
                    );
            })
        );


    @Effect()
    amount$ = this.actions$.pipe(ofType(searchAction.GET_AMOUNT))
        .pipe(
            switchMap( (action: searchAction.GetAmount) => {
                return this.parkingService.getAmount(action.data)
                    .pipe(
                        map( amount => new searchAction.GetAmountSuccess(amount) ),
                        catchError( error => of(new searchAction.GetAmountFail(error)) )
                    );
            })
        );

    @Effect()
    voucher$ = this.actions$.pipe(ofType(searchAction.CREATE_VOUCHER))
        .pipe(
            switchMap( (action: searchAction.CreateVoucher) => {
                return this.parkingService.createVoucher(action.voucher)
                    .pipe(
                        map( url => new searchAction.CreateVoucherSucces(url) ),
                        catchError( error => of(new searchAction.CreateVoucherFail(error)) )
                    );
            })
        );

    @Effect()
    terms$ = this.actions$.pipe(ofType(searchAction.GET_TERMS))
        .pipe(
            switchMap( (action: searchAction.GetTermsAction) => {
                return this.parkingService.getTermsAndConditions(action.idParking, action.lan)
                    .pipe(
                        map( url => new searchAction.GetTermsSuccess(url) ),
                        catchError( error => of(new searchAction.GetTermsFail(error)) )
                    );
            })
        );

    @Effect()
    countries$ = this.actions$.pipe(ofType(searchAction.GET_COUNTRIES))
        .pipe(
            switchMap( (action: searchAction.GetCountriesAction) => {
                return this.parkingService.getCountries(action.lan)
                    .pipe(
                        map( countries => new searchAction.GetCountriesSuccess(countries) ),
                        catchError( error => of(new searchAction.GetCountriesFail(error)) )
                    );
            })
        );

    @Effect()
    summary$ = this.actions$.pipe(ofType(searchAction.GET_SUMMARY))
        .pipe(
            switchMap( (action: searchAction.GetSummaryAction) => {
                return this.parkingService.orderSummary(action.hash, action.lan)
                    .pipe(
                        map( summary => new searchAction.GetSummarySuccess(summary) ),
                        catchError( error => of(new searchAction.GetSummaryFail(error)) )
                    );
            })
        );
}
