import { Component, OnInit } from '@angular/core';
import {TagManagerService} from '../../../services/tag-manager.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.sass']
})
export class ConfirmComponent implements OnInit {

  public billShow : boolean = false;

  constructor(private tag: TagManagerService) { }

  ngOnInit() {
    // Analytics
    this.tag.sendDynamicDataLayer({
      processCategory: '',
      processName: 'Pago',
      processStep: '2',
      processStatus: '3'
    });
  }

  txtNif: string;
  txtName: string;
  txtAddress: string;
  txtPostal: string;
  txtCity: string;
}
