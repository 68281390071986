export class Location {
  public name: string;
  public address: string;
  public city: string;
  public lat: number;
  public lng: number;

  constructor(address: any) {
    this.name = address.name;
    this.address = address.formatted_address;
    this.lat = address.geometry.location.lat();
    this.lng = address.geometry.location.lng();
    try {
      this.city = address.address_components.forEach((item) => {
        if (item.types.indexOf('locality') > -1) {
          this.city = item.long_name;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}
