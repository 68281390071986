import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.reducers';
import * as searchAction from '../search/search.actions';

@Component({
  selector: 'app-info-parking',
  templateUrl: './info-parking.component.html',
  styleUrls: ['./info-parking.component.sass']
})
export class InfoParkingComponent implements OnInit, OnDestroy {

  constructor(private store: Store<AppState>) { }
  name: string;
  address: string;
  fare: string;
  price: string;
  date: string;
  observe: any;
  amount: any;
  cont = 0;
  dateAmount: any;
  dateMax: any;

  ngOnInit() {
    this.observe = this.store.subscribe(state => {
      if (state.address.parking && state.address.fare) {
        this.name = state.address.parking.name;
        this.address = state.address.parking.address;
        this.fare = state.address.fare.descripcion;
        this.price = state.address.fare.precio;
        this.date = state.address.fare.fechaInicio;
        if (state.address.amount) {
          this.amount = state.address.amount;

          this.dateAmount = state.address.amount.fechaMax;
          const dateAmount = new Date(this.dateAmount);
          this.dateAmount =  ('0' + (dateAmount.getMonth()+1)).slice(-2) + '-' + ('0' + (dateAmount.getDate())).slice(-2) + '-' + dateAmount.getFullYear();
          this.dateMax = state.address.amount.fechaMaxPrecioDecuento;
          if(this.dateMax != null){
            const dateMax = new Date(this.dateMax);
            this.dateMax =  ('0' + (dateMax.getMonth()+1)).slice(-2) + '-' + ('0' + (dateMax.getDate())).slice(-2) + '-' + dateMax.getFullYear();
          }
        }
        let date = new Date(this.date);

        let data = {
            rateId: state.address.fare.idTarifa,
            startDate: date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2)
        };
        if(this.cont == 0){
          const accion = new searchAction.GetAmount(data);
          this.store.dispatch( accion );
          this.cont ++;
        }


      }
    });
  }

  ngOnDestroy(): void {
    this.observe.unsubscribe();
  }

}
