import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare const dataLayer: any;
declare let ga: any;

@Injectable({
  providedIn: 'root'
})
export class TagManagerService {
  tracker: any;

  constructor(private translate: TranslateService) {
    try {
      this.tracker = ga.getAll()[0];
    } catch (e) {
      console.log('Error al acceder al objeto ga');
    }
  }

  sendStaticDataLayer() {
    try {
      dataLayer.push({
        // User
        userGA: this.tracker.get('clientId'),
        userID: null,
        userStatus: 'no logado',
        userCars: null,
        // Traffic
        pageDomain: window.location.host,
        pageTitle: document.title,
        pageName: window.location.host + ' - ' + document.title,
        pageArea: 'pública',
        pageLanguage: this.translate.currentLang,
        pageURL: window.location.href
      });
    } catch (e) {
      console.error(e);
    }
  }

  sendDynamicDataLayer(d) {
    try {
      dataLayer.push(d);
    } catch (e) {
      console.error(e);
    }
  }
}
