import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';
import { Helper } from '../../../services/helper';
import * as searchAction from '../../../components/search/search.actions';
import { ActivatedRoute } from '@angular/router';
import { TagManagerService } from '../../../services/tag-manager.service';

@Component({
  selector: 'app-confirm-ok',
  templateUrl: './confirm-ok.component.html',
  styleUrls: ['./confirm-ok.component.sass']
})
export class ConfirmOkComponent implements OnInit, OnDestroy, AfterViewInit {
  summary: any;
  observe: any;
  amount: any;
  hash: any;
  lan: any;

  constructor(
    private store: Store<AppState>,
    private helper: Helper,
    private route: ActivatedRoute,
    private tag: TagManagerService
  ) {}

  ngAfterViewInit(): void {
    this.helper.getScreenSize('');
  }

  ngOnInit() {
    this.observe = this.store.subscribe((state) => {
      if (state.address.summary) {
        this.summary = state.address.summary;
      }
      if (state.address.amount) {
        this.amount = state.address.amount.amount;
      }
      this.lan = state.address.lan;
    });

    this.route.queryParams.subscribe((params) => {
      this.hash = params.hash;
      this.goSummary();
    });

    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '14Fin compra abono',
      eventLabel: '14Fin compra abono'
    });

    // Analytics
    this.tag.sendDynamicDataLayer({
      processCategory: '',
      processName: 'Confirmación',
      processStep: '3',
      processStatus: '3'
    });
  }

  ngOnDestroy(): void {
    this.observe.unsubscribe();
  }

  goSummary() {
    const accion = new searchAction.GetSummaryAction(this.hash, this.lan);
    this.store.dispatch(accion);
  }
}
