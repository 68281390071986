import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { Router } from '@angular/router';
import { Fare } from '../../models/fare';
import { FormBuilder, Validators } from '@angular/forms';
import * as searchAction from '../search/search.actions';
import { TagManagerService } from '../../services/tag-manager.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detail-parking',
  templateUrl: './detail-parking.component.html',
  styleUrls: ['./detail-parking.component.sass']
})
export class DetailParkingComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild('mapContainer') gmap: ElementRef;
  map: google.maps.Map;
  parking: any;
  observe: any;
  fare: Fare;
  piker: any;
  image: any;
  schedule: any = [];
  isPortugal: boolean;
  dateRange1 = [new Date(1990, 1, 1), new Date().setDate(new Date().getDate() + 7)];
  myFilter = (d: Date): boolean => {
    // Prevent dates in ranges from being selected.
    return !(d >= this.dateRange1[0] && d <= this.dateRange1[1]);
  };
  dateForm = this.fb.group({
    pikerInput: ['', Validators.required]
  });

  mapInitializer(lat, lng) {
    const zoom = 9;
    const coordinates = new google.maps.LatLng(lat, lng);
    const mapOptions: google.maps.MapOptions = {
      center: coordinates,
      disableDefaultUI: true,
      zoom: zoom,
      styles: [
        {
          stylers: [
            {
              saturation: -100
            }
          ]
        }
      ]
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    this.createMarker(lat, lng);
  }

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private fb: FormBuilder,
    private tag: TagManagerService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.observe = this.store.subscribe((state) => {
      if (state.address.parking != undefined) {
        this.parking = state.address.parking;
        this.schedule = [];
        state.address.parking.countryCode == 'ES' ? (this.isPortugal = false) : (this.isPortugal = true);

        this.parking.tarifasList.map((fare) => {
          // Delete: Only for development
          if (fare.precioConDescuento) {
            //fare.fechaFin = new Date('2021-02-01');
          }
          //
          for (const hour of fare) {
            const arrayHour = hour.split(' ');
            const objectDays = {
              day: arrayHour[0],
              hour: arrayHour[1]
            };
            this.schedule.push(objectDays);
          }
          if (fare.fechaFinDescuento > new Date()) {
            fare.showPromo = true;
          }
        });
        this.mapInitializer(this.parking.coordenadas.latitud, this.parking.coordenadas.longitud);
        this.sortByMinimumPrice();
      }
    });
  }

  ngOnDestroy(): void {
    this.observe.unsubscribe();
  }

  sortByMinimumPrice(): void {
    this.parking.tarifasList = this.parking.tarifasList.sort((a1: any, a2: any) => {
      let p1 = 0;
      let p2 = 0;
      if (a1.precioConDescuento) {
        p1 = a1.precioConDescuento;
      } else {
        p1 = a1.precio;
      }
      if (a2.precioConDescuento) {
        p2 = a2.precioConDescuento;
      } else {
        p2 = a2.precio;
      }
      if (p1 > p2) {
        return 1;
      }
      if (p1 < p2) {
        return -1;
      }
      return 0;
    });
  }

  createMarker(lat, lng) {
    const imageObj = new Image();
    imageObj.src = '/assets/img/ic_pin_orange.png';
    imageObj.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 60;
      canvas.height = 39;
      const context = canvas.getContext('2d');
      context.font = '15px Arial';
      context.textAlign = 'center';
      context.drawImage(imageObj, 0, 0);
      this.image = {
        url: canvas.toDataURL()
      };
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: this.map,
        icon: this.image
      });
    };
  }

  goPayment() {
    this.fare.fechaInicio = this.dateForm.value.pikerInput;
    const accion = new searchAction.SelectFare(this.fare);
    this.store.dispatch(accion);
    this.router.navigateByUrl('/payment-proccess/personal-data');
    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '7clic en comprar abono',
      eventLabel: `7${this.fare.descripcionProducto}`
    });
    this.tag.sendDynamicDataLayer({
      estacionamientoBarrio: this.parking.address,
      bonoAparcamiento: this.fare.descripcion,
      bonoFechaInicio: this.fare.fechaInicio.toLocaleDateString()
    });
  }

  closeView() {
    this.fare = undefined;
    this.close.emit(true);
  }

  saveFare(fare) {
    this.fare = fare;
    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '4Seleccion de abono',
      eventLabel: `4${fare.descripcionProducto}`
    });
  }

  datePickerFocus(picker) {
    picker.open();
    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '5Clic en Selector de fecha',
      eventLabel: '5Clic en Selector de fecha'
    });
  }

  dateSelected(date) {
    // Analytics
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '6Seleccion de fecha de inicio',
      eventLabel: '6Seleccion de fecha de inicio'
    });

    const day = date.substr(0, 2);
    const year = date.substr(5, 6);
    const month = date.substr(8, 11);
    const myDate = year + '-' + month + '-' + day;

    this.parking.tarifasList.map((fare) => {
      fare.showPromo = fare.fechaFin > new Date(myDate);
    });
  }

  open(content, event: Event) {
    event.stopPropagation();
    this.modalService.open(content);
  }

  closePrivacyModalGaEvent() {
    this.modalService.dismissAll();

  }

}
