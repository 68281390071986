import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-payment-proccess',
  templateUrl: './payment-proccess.component.html',
  styleUrls: ['./payment-proccess.component.sass']
})
export class PaymentProccessComponent implements OnInit {

  router: Router;

  constructor(router: Router) {

    this.router = router;
  }

  ngOnInit() {
  }

}
