import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { TagManagerService } from './tag-manager.service';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(private tag: TagManagerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
    return next.handle(req).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
          this.tag.sendDynamicDataLayer({
            errorCode: 'client side',
            errorDescription: errorMessage
          });
        } else {
          // server-side error
          errorMessage = `Error: ${error.message}`;
          this.tag.sendDynamicDataLayer({
            errorCode: 'server side -> code: ' + error.status,
            errorDescription: errorMessage
          });
        }
        return throwError(errorMessage);
      })
    );
  }
}
