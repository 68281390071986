import * as searchAction from './search.actions';

const estadoInicial: [] = getStateStorage();

export function searchReducer(state = estadoInicial, action: searchAction.Acciones) {
    let newState;
    switch (action.type) {
        case searchAction.CLEAR_STATE:
            newState = {
                loading: false
            };
            setStateStorage(newState);
            return newState;
        case searchAction.SEARCH_ADDRESS:
            newState = {
                ...state,
                coordinates: action.address,
                loading: true,
                search: true,
                parking: null,
            };
            setStateStorage(newState);
            return newState;
        case searchAction.SEARCH_PARKING:
            newState = {
                ...state,
                loading: true,
                search: false
            };
            setStateStorage(newState);
            return newState;
        case searchAction.SAVE_LAN:
            newState = {
                ...state,
                loading: false,
                search: false,
                lan: action.lan
            };
            setStateStorage(newState);
            return newState;
        case searchAction.SEARCH_PARKING_SUCCESS:
            newState = {
                ...state,
                loading: false,
                search: false,
                parkings: [...action.parkings]
            };
            setStateStorage(newState);
            return newState;
        case searchAction.SEARCH_PARKING_FAIL:
            newState = {
                ...state,
                loading: false,
                search: false,
                error: {
                    status: action.payload,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
            setStateStorage(newState);

            return newState;
        case searchAction.SELECT_FARE:
            newState = {
                ...state,
                loading: false,
                search: false,
                fare: action.fare
            };
            setStateStorage(newState);
            return newState;
        case searchAction.CREATE_VOUCHER:
            newState = {
                ...state,
                loading: true,
                search: false
            };
            setStateStorage(newState);
            return newState;
        case searchAction.SAVE_PERSONAL_DATA:
            newState = {
                ...state,
                loading: false,
                search: false,
                personalData: action.personalData
            };
            setStateStorage(newState);
            return newState;
        case searchAction.CREATE_VOUCHER_SUCCESS:
            newState = {
                ...state,
                loading: false,
                search: false,
                url: action.url
            };
            setStateStorage(newState);
            return newState;
        case searchAction.CREATE_VOUCHER_FAIL:
            newState = {
                ...state,
                loading: false,
                search: false,
                error: {
                    status: action.payload,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_PARKING:
            newState = {
                ...state,
                loading: false,
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_PARKING_SUCCESS:
            newState = {
                ...state,
                loading: false,
                parking: action.parking
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_PARKING_FAIL:
            newState = {
                ...state,
                loading: false,
                search: false,
                error: {
                    status: action.payload,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_AMOUNT:
            newState = {
                ...state,
                loading: false,
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_AMOUNT_SUCCESS:
            newState = {
                ...state,
                loading: false,
                amount: action.amount
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_AMOUNT_FAIL:
            newState = {
                ...state,
                loading: false,
                search: false,
                error: {
                    status: action.payload,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_PARKING_EMPARK:
            newState = {
                ...state,
                loading: false,
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_PARKING_EMPARK_SUCCESS:
            newState = {
                ...state,
                loading: false,
                parking: action.parking
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_PARKING_EMPARK_FAIL:
            newState = {
                ...state,
                loading: false,
                search: false,
                error: {
                    status: action.payload,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_TERMS:
            newState = {
                ...state
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_TERMS_SUCCESS:
            newState = {
                ...state,
                loading: false,
                search: false,
                terms: action.terms
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_TERMS_FAIL:
            newState = {
                ...state,
                loading: false,
                search: false,
                error: {
                    status: action.payload,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_COUNTRIES:
            newState = {
                ...state
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_COUNTRIES_SUCCESS:
            newState = {
                ...state,
                loading: false,
                search: false,
                countries: action.countries
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_COUNTRIES_FAIL:
            newState = {
                ...state,
                loading: false,
                search: false,
                error: {
                    status: action.payload,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_SUMMARY:
            newState = {
                ...state
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_SUMMARY_SUCCESS:
            newState = {
                ...state,
                loading: false,
                search: false,
                summary: action.summary
            };
            setStateStorage(newState);
            return newState;
        case searchAction.GET_SUMMARY_FAIL:
            newState = {
                ...state,
                loading: false,
                search: false,
                error: {
                    status: action.payload,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
            setStateStorage(newState);
            return newState;
        default:
            setStateStorage(state);
            return state;
    }
}

export function setStateStorage(state) {
    localStorage.setItem('state', JSON.stringify(state));
}

export function getStateStorage() {
    if (localStorage.getItem('state') != null) {
        let json = JSON.parse(localStorage.getItem('state'));
        delete json.error;
        delete json.url;
        return json;
    } else {
        return {loading: false};
    }
}
