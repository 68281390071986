import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.reducers';

@Component({
  selector: 'app-text-terms',
  templateUrl: './text-terms.component.html',
  styleUrls: ['./text-terms.component.sass']
})
export class TextTermsComponent implements OnInit, OnDestroy {

  constructor(private store: Store<AppState>) { }
  html: any;
  observe: any;
  ngOnInit() {
    this.observe = this.store.subscribe(state => {
      if (state.address.terms != undefined) {
        this.html = state.address.terms;
      }
    });
  }

  ngOnDestroy(): void {
    this.observe.unsubscribe();
  }

}
