import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as searchAction from '../../components/search/search.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { Helper } from '../../services/helper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  private route$: Subscription;
  lan: string;
  country: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private helper: Helper,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {}

  ngAfterViewInit(): void {
    this.helper.getScreenSize('home');
  }

  ngOnInit() {
    this.route$ = this.activeRoute.params.subscribe((params: Params) => {
      this.lan = params.lan;
      const clear = new searchAction.ClearStateAction();
      this.store.dispatch(clear);
      const accion = new searchAction.SaveLanAction(this.lan.toUpperCase());
      this.store.dispatch(accion);
      const countries = new searchAction.GetCountriesAction(this.lan.toUpperCase());
      this.store.dispatch(countries);
      this.translate.use(params.lan);
      this.country = params.country;
    });
  }

  ngOnDestroy(): void {
    this.route$.unsubscribe();
  }
}
