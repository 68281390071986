import {HostListener, Injectable} from '@angular/core';

@Injectable()
export class Helper {

    scrHeight:any;
    scrWidth:any;
    timeLeft: number = 60;
    interval;

    @HostListener('window:resize', ['$event'])
    getScreenSize(id) {
        let body = document.body,
            html = document.documentElement;
        this.interval = setInterval(() => {
            let height = Math.max( body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight );
            if(id == 'home'){
                height = 1660;
            }else{
                height = 978;
            }
            window.parent.postMessage(['setHeight', height], '*');
            clearInterval(this.interval);
        },500);
    }
}
