import { Routes } from '@angular/router';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { PaymentDataComponent } from './payment-data/payment-data.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmOkComponent } from './confirm-ok/confirm-ok.component';


export const PAYMENT_PROCCESS_ROUTES: Routes = [
    {path: 'personal-data', component: PersonalDataComponent},
    {path: 'payment-data', component: PaymentDataComponent},
    {path: 'confirm', component: ConfirmComponent},
    {path: 'confirm-ok', component: ConfirmOkComponent},
    {path: '', pathMatch: 'full', redirectTo: 'personal-data' }
];
