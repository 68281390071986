import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './subscriptions/home/home.component';
import { SearchComponent } from './components/search/search.component';
import { MapComponent } from './subscriptions/map/map.component';
import { ListParkingComponent } from './components/list-parking/list-parking.component';
import { PaymentProccessComponent } from './subscriptions/payment-proccess/payment-proccess.component';
import { PersonalDataComponent } from './subscriptions/payment-proccess/personal-data/personal-data.component';
import { PaymentDataComponent } from './subscriptions/payment-proccess/payment-data/payment-data.component';
import { ConfirmComponent } from './subscriptions/payment-proccess/confirm/confirm.component';
import { ConfirmOkComponent } from './subscriptions/payment-proccess/confirm-ok/confirm-ok.component';
import { DetailParkingComponent } from './components/detail-parking/detail-parking.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MatFormFieldModule, MatIconModule, MatNativeDateModule} from '@angular/material';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import {AppReducers} from './app.reducers';

import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {effectsArray} from './effects';
import {EffectsModule} from '@ngrx/effects';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {JWTInterceptor} from './services/JWTInterceptor';
import {InternationalPhoneNumberModule} from 'ngx-international-phone-number';
import {MatSelectCountryModule} from '@angular-material-extensions/select-country';
import { InfoParkingComponent } from './components/info-parking/info-parking.component';
import { TextTermsComponent } from './components/text-terms/text-terms.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MAT_DATE_LOCALE} from '@angular/material';
import {MyDateAdapter} from './services/MyDateAdapter';
import {Helper} from './services/helper';
import {DayPipe} from './services/day.pipe';
import {HourPipe} from './services/hour.pipe';
import {NgxFlagPickerModule} from 'ngx-flag-picker';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SearchComponent,
    MapComponent,
    ListParkingComponent,
    DetailParkingComponent,
    ListParkingComponent,
    PaymentProccessComponent,
    PersonalDataComponent,
    PaymentDataComponent,
    ConfirmComponent,
    ConfirmOkComponent,
    InfoParkingComponent,
    TextTermsComponent,
    HourPipe,
    DayPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatFormFieldModule,
    MatSelectCountryModule,
    HttpClientModule,
    InternationalPhoneNumberModule,
    MatNativeDateModule,
    MatIconModule,
    NgxFlagPickerModule,
    NgbModule,
    EffectsModule.forRoot(effectsArray),
    StoreModule.forRoot(AppReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {provide: DateAdapter, useClass: MyDateAdapter},
      Helper,
      DayPipe,
      HourPipe

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
