
import { Pipe, PipeTransform } from '@angular/core';
/*
 * Capitalize the first letter of the string
 * Takes a string as a value.
 * Usage:
 *  value | capitalizefirst
 * Example:
 *  // value.name = daniel
 *  {{ value.name | capitalizefirst  }}
 *  fromats to: Daniel
 */
@Pipe({
    name: 'day'
})
export class DayPipe implements PipeTransform {

    transform(value: string): string {
        let arrayHour = '';
        if (value != null) {
            arrayHour = value.split(' ')[0];
        }
        return arrayHour;
        }
    }
