export class Coordinates {
    public idioma: string;
    public noreste: any;
    public suroeste: any;

    constructor( lang: string, NE: any, SW: any ) {
        this.idioma = lang;
        this.noreste = {
            latitud: NE.lat(),
            longitud: NE.lng()
        };
        this.suroeste = {
            latitud: SW.lat(),
            longitud: SW.lng()
        };
    }
}
