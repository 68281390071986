import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.reducers';
import * as searchAction from '../search/search.actions';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';
import {TagManagerService} from '../../services/tag-manager.service';

@Component({
    selector: 'app-list-parking',
    templateUrl: './list-parking.component.html',
    styleUrls: ['./list-parking.component.sass']
})
export class ListParkingComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() open: EventEmitter<any> = new EventEmitter();
    parkings: any = [];
    idParking: any;
    lan: string;
    observe: any;
    id: any;
    cont = 0;
    private route$: Subscription;

    constructor(private store: Store<AppState>, private activeRoute: ActivatedRoute, private tag: TagManagerService) {
    }

    ngAfterViewInit(): void {
        this.route$ = this.activeRoute.params.subscribe((params: Params) => {
            this.lan = params.lan;
            if (this.lan !== undefined) {
                const accion = new searchAction.SaveLanAction(this.lan.toUpperCase());
                this.store.dispatch(accion);
            }
            this.id = params.idAparcamiento;
            if (this.id !== undefined) {
                this.openViewEmpark(this.id);
                this.cont = 0;
            }
        });
    }

    ngOnInit() {
        this.observe = this.store.subscribe((state) => {
            if (state.address.parkings !== undefined) {
                this.parkings = state.address.parkings;
            }
            this.lan = state.address.lan;

            if (state.address.parking) {
                this.idParking = state.address.parking.idAparcamiento;
                if (this.cont === 0 && this.id !== undefined) {
                    const address = {
                        name: state.address.parking.city,
                        address: state.address.parking.address,
                        lat: state.address.parking.coordenadas.latitud,
                        lng: state.address.parking.coordenadas.longitud
                    };
                    this.cont++;
                    const accion = new searchAction.SearchAddressAction(address);
                    this.store.dispatch(accion);
                }
            }
            this.sortByMinimumPrice();
        });
    }

    sortByMinimumPrice(): void {
        if (this.parkings) {
            this.parkings.map((parking) => {
                parking.tarifasList = parking.tarifasList.sort((a1: any, a2: any) => {
                    let p1 = 0;
                    let p2 = 0;
                    if (a1.precioConDescuento) {
                        p1 = a1.precioConDescuento;
                    } else {
                        p1 = a1.precio;
                    }
                    if (a2.precioConDescuento) {
                        p2 = a2.precioConDescuento;
                    } else {
                        p2 = a2.precio;
                    }
                    if (p1 > p2) {
                        return 1;
                    }
                    if (p1 < p2) {
                        return -1;
                    }
                    return 0;
                });
            });
        }
    }


    ngOnDestroy(): void {
        this.observe.unsubscribe();
    }

    openViewEmpark(idParking) {
        const accion = new searchAction.GetParkingEmpark(idParking, this.lan);
        this.store.dispatch(accion);
        this.open.emit(false);
    }

    openView(event, parking) {
        if (this.parkings.length !== 0) {
            const parkingsFiltered = this.parkings.filter((x) => x.idAparcamiento === parking.idAparcamiento);
            if (this.cheapPrice(parkingsFiltered[0].tarifasList, false) === 0) {
                return false;
            } else {
                const accion = new searchAction.GetParking(parking.idAparcamiento, this.lan);
                const terms = new searchAction.GetTermsAction(parking.idAparcamiento, this.lan);
                this.store.dispatch(accion);
                this.store.dispatch(terms);
                this.open.emit(event);
            }
        } else {
            const accion = new searchAction.GetParking(parking.idAparcamiento, this.lan);
            const terms = new searchAction.GetTermsAction(parking.idAparcamiento, this.lan);
            this.store.dispatch(accion);
            this.store.dispatch(terms);
            this.open.emit(event);
        }
        // Analytics
        this.tag.sendDynamicDataLayer({
            event: 'gaEvent',
            eventCategory: 'Proceso de Abonos',
            eventAction: '3Seleccion parking',
            eventLabel: `3${parking.name}_${parking.city}`
        });
    }

    cheapPriceDesc(fareList, desc) {
        if (fareList.length === 0) {
            return 0;
        }
        const pricesDesc = [];
        fareList.forEach((fare) => {
            if (fare.precioConDescuento != null) {
                pricesDesc.push(fare.precioConDescuento);
            }
        });

        let temp = [];
        for (let i = 1; i < pricesDesc.length; i++) {
            for (let j = 0; j < pricesDesc.length - 1; j++) {
                if (pricesDesc[j + 1] < pricesDesc[j]) {
                    temp = pricesDesc[j];
                    pricesDesc[j] = pricesDesc[j + 1];
                    pricesDesc[j + 1] = temp;
                }
            }
        }
        if (desc === false) {
            for (var i = 0; i < fareList.length; i++) {
                // look for the entry with a matching `code` value

                if (pricesDesc[0] != 0) {
                    if (fareList[i].precioConDescuento == pricesDesc[0]) {
                        return fareList[i].precio;
                    }
                }
            }
        }
        if (pricesDesc[0] !== 0) {
            return pricesDesc[0].toString();
        } else {
            let result = null;
            result = pricesDesc[pricesDesc.length - 1].toString();
            if (result == null) {
                return '';
            } else {
                return result.toString();
            }
        }

    }

    cheapPrice(fareList, desc) {
        if (fareList.length === 0) {
            return 0;
        }
        const prices = [];
        if (desc) {
            fareList.forEach((fare) => {
                if (fare.precioConDescuento != null) {
                    prices.push(fare.precioConDescuento);
                } else {
                    prices.push(0);
                }
            });
        } else {
            fareList.forEach((fare) => {
                if (fare.precio != null) {
                    prices.push(fare.precio);
                } else {
                    prices.push(0);
                }
            });
        }


        let temp = [];
        for (let i = 1; i < prices.length; i++) {
            for (let j = 0; j < prices.length - 1; j++) {
                if (prices[j + 1] < prices[j]) {
                    temp = prices[j];
                    prices[j] = prices[j + 1];
                    prices[j + 1] = temp;
                }
            }
        }
        if (prices[0] != 0) {
            return prices[0].toString();
        } else {
            let result = null;
            prices.forEach((price) => {
                if (price != 0) {
                    result = price;
                }
            });
            if (result == null) {
                return '';
            } else {
                return result.toString();
            }
        }

    }
}
