import { Component, ElementRef, ViewChild, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AppState } from '../../app.reducers';
import { Store } from '@ngrx/store';
import * as searchAction from './search.actions';
import { Location } from '../../models/location';
import { Router } from '@angular/router';
import { TagManagerService } from '../../services/tag-manager.service';
import { Subscription } from 'rxjs';
declare var google: any;

@Component({
  selector: 'app-search-parking',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @ViewChild('place') place: ElementRef;
  address: any;
  observe: Subscription;
  timeLeft: number = 60;
  interval;

  constructor(private store: Store<AppState>, private router: Router, private tag: TagManagerService) {}

  ngOnInit(): void {
    // Observe state
    this.observe = this.store.subscribe((state) => {
      if (
        state &&
        state.address &&
        state.address.coordinates &&
        state.address.coordinates.address &&
        !state.address.search &&
        !state.address.loading
      ) {
        // Analytics
        this.tag.sendDynamicDataLayer({
          searchKeyword: state.address.coordinates.address,
          searchResults: state.address.parkings.length,
          searchPageNum: 1
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.observe.unsubscribe();
  }

  public handleAddressChange(address: Address) {
    // Do some stuff
    this.place.nativeElement.focus();
    this.address = new Location(address);
  }

  searchAddress() {
    if (this.address !== undefined) {
      const accion = new searchAction.SearchAddressAction(this.address);
      this.store.dispatch(accion);
      // Analytics
      this.tag.sendDynamicDataLayer({
        event: 'gaEvent',
        eventCategory: 'Proceso de Abonos',
        eventAction: '2Clic en buscar abono',
        eventLabel: `2${this.address.name}`
      });

      this.router.navigateByUrl('/map');
    }
  }

  focusSearchInput() {
    this.tag.sendDynamicDataLayer({
      event: 'gaEvent',
      eventCategory: 'Proceso de Abonos',
      eventAction: '1Inicio compra abono',
      eventLabel: '1Inicio compra abono'
    });
  }

  searchFirstResult() {
    //this.searchAddress();
    const hasResults = document.getElementsByClassName('pac-item').length;
    const hiddenMap = document.getElementById('hidden-map');
    const seekerValue = this.place.nativeElement.value;
    if (hasResults && seekerValue) {
      const autocompleteService = new google.maps.places.AutocompleteService();
      const placeService = new google.maps.places.PlacesService(hiddenMap);
      autocompleteService.getQueryPredictions({ input: seekerValue }, (predictions, autocompleteStatus) => {
        if (autocompleteStatus === google.maps.places.PlacesServiceStatus.OK) {
          placeService.getDetails({ placeId: predictions[0].place_id }, (place, placeStatus) => {
            if (placeStatus === google.maps.places.PlacesServiceStatus.OK) {
              this.handleAddressChange(place);
              document.getElementById('btn-search').click();
              //this.searchAddress();
              this.place.nativeElement.value = predictions[0].description;
            }
          });
        }
      });
    }
  }
}
