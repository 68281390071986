
export class PersonalData {
    public apellidos: string;
    public comentarios: any;
    public dni: string;
    public email: any;
    public idioma: any;
    public codigoPais: any;
    public codigoIdioma: any;
    public ciudad: any;
    public codigoPostal: any;
    public direccion: any;
    public matricula: any;
    public nombreCliente: any;
    public telefono: any;
    public sendNotifications: any;
    public invoiceData: any;

    constructor( apellidos: any,
                 comentarios: any,
                 dni: any,
                 email: any,
                 idioma: any,
                 codigoPais: any,
                 ciudad: any,
                 codigoPostal: any,
                 codigoIdioma: any,
                 direccion: any,
                 matricula: any,
                 sendNotifications: any,
                 invoiceData: any,
                 nombreCliente: any,
                 telefono: any) {
        this.apellidos = apellidos;
        this.comentarios = comentarios;
        this.invoiceData = invoiceData;
        this.codigoPais = codigoPais;
        this.codigoIdioma = codigoIdioma;
        this.ciudad = ciudad;
        this.codigoPostal = codigoPostal;
        this.direccion = direccion;
        this.dni = dni;
        this.email = email;
        this.idioma = idioma;
        this.matricula = matricula;
        this.nombreCliente = nombreCliente;
        this.telefono = telefono;
        this.sendNotifications = sendNotifications;
    }
}
